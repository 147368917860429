<template>
  <div class="container">
    <div class="head flex">
      <h1 @click="navBar()">{{ aboutname }}</h1>
      <p
        v-if="
          aboutname != columnName && columnName != undefined && aboutname != ''
        "
      >
        |
      </p>
      <h1 v-if="aboutname != columnName" class="color2c">{{ columnName }}</h1>
      <p>
        <span @click="jumpHome()">首页</span>
        <span
          v-if="
            aboutname != columnName &&
              columnName != undefined &&
              aboutname != ''
          "
          @click="navBar()"
          >{{ aboutname }}</span
        >
        <span>{{ columnName }}</span>
      </p>
    </div>
    <div class="bottom flex">
      <div class="left">
        <div class="banner" v-if="this.Img.length > 0">
          <div class="item" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <img :src="Img[currentIndex]" @click="handleSwiperDetail()" />
          </div>
          <div class="page" v-if="this.Img.length">
            <div class="font">{{ label[currentIndex] }}</div>
            <ul style="" class="ul">
              <li
                v-for="(item, index) in Img"
                :key="index"
                @click="gotoPage(index)"
                :class="{ current: currentIndex == index }"
                class="li"
              ></li>
            </ul>
          </div>
        </div>
        <div class="banner" v-else>
          <div class="item">
            <img src="http://218.107.33.44:9184/images/404.jpg" />
          </div>
          <!-- <div class="no_data">暂无数据</div> -->
        </div>
        <div class="list" v-if="list.length > 0">
          <ul>
            <li
              class="flex"
              v-for="(item, idx) in list"
              :key="idx"
              @click="handleDetail(item)"
            >
              <div><img :src="item.icon" /></div>
              <div>
                <p class="infor">{{ item.title }}</p>
                <div class="flex source">
                  <p v-if="item.sourceValue != '' || item.websetName != ''">
                    来源:{{
                      item.sourceValue == ""
                        ? item.websetName
                        : item.sourceValue
                    }}
                  </p>
                  <p v-if="item.sourceValue == '' && item.websetName == ''">
                    来源:本网站
                  </p>
                  <p>
                    发布时间:<span>{{ item.releaseTime.slice(0, 16) }}</span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="list" v-else>
          <ul>
            <li class="flex" v-for="(item, idx) in 4" :key="idx">
              <div><img src="http://218.107.33.44:9184/images/404.jpg" /></div>
              <div>
                <p class="infor">暂无数据</p>
                <div class="flex source">
                  <p v-if="item.sourceValue != '' || item.websetName != ''">
                    来源:本网站
                  </p>
                  <p>
                    发布时间:
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <h1>即时热点<span>HOT</span></h1>
          <ul>
            <li
              v-for="(item, idx) in hotList"
              :key="idx"
              @click="hotNewsDetail(item)"
            >
              <i
                ><span>{{ idx + 1 }}</span></i
              >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div v-if="bannerImg.length > 0">
          <template v-if="bannerImg.length > 1">
            <el-carousel indicator-position="none" arrow="never">
              <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
                <img :src="item.picturePath" style="width:100%;" />
              </el-carousel-item>
            </el-carousel>
          </template>
          <template v-else>
            <div class="ad" v-for="(item, idx) in bannerImg" :key="idx">
              <img :src="item.picturePath" alt="" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="Pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getBanner } from "@/api/banner";
import { getQuotesList, getNews } from "@/api/agricuinfotThree";
import { getdetail } from "@/api/home1";
import Footer from "@/views/components/footer.vue";
export default {
  components: { Footer },
  props: {
    columnNameId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      Img: [],
      label: [],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      list: [],
      hotList: [],
      aboutname: "",
      columid: "",
      paths: "",
      columnName: "",
      //   columnNameId: "",
      baner: "",
      bannerImg: "",
      titalPage: "",
      current: 0,
      total: 10,
      size: 10,
      level: ""
    };
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.Img.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex == this.Img.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    }
  },
  mounted() {
    this.fetchColumnList();
    this.runInv();
  },
  methods: {
    //  导航跳转
    navBar() {
      this.$router.push({
        path: "/commonLayout",
        query: {
          columid: this.columid,
          level: "1",
          columnNameId: this.columid,
          columnName: this.aboutname
        }
      });
    },
    //   首页跳转
    jumpHome() {
      this.$router.push("/home");
    },
    // 正文跳转
    handleDetail(item) {
      const detailRouter = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          titleId: item.id
        }
      });
      window.open(detailRouter.href, "_blank");
    },

    //获取导航栏栏目列表
    async fetchColumnList() {
      await getdetail({
        id: this.$route.query.columnNameId
        // id: this.columnNameId
      }).then(res => {
        this.columnName = res.data.columnName;
        this.aboutname = res.data.parentColumnName;
        this.paths = res.data.parentAccessPath;
        this.columid = res.data.parentId;
        this.level = res.data.level;
      });
      this.$nextTick(() => {
        this.fetchThumbnailList();
        this.fetchHotNewsList();
        this.fetchBanner();
      });
    },

    // 获取缩略图列表
    async fetchThumbnailList() {
      await getQuotesList({
        columnId: this.$route.query.columnNameId,
        // columnId: this.columnNameId,
        size: this.size,
        current: this.current
      }).then(res => {
        const data = res.data;
        if (res) {
          this.size = data.size;
          this.current = data.current;
          this.total = data.total;
          if (data.records.length > 0) {
            this.list = data.records;
            this.baner = data.records;
            for (let i = 0; i < 3; i++) {
              this.Img.push(data.records[i].icon);
              this.label.push(data.records[i].title);
            }
          }
        }
      });
    },

    // 即时热点列表
    async fetchHotNewsList() {
      await getNews({
        type: 2
        // columnId: this.$route.query.columnNameId
      }).then(res => {
        this.hotList = res.data.records;
      });
    },
    //轮播图详情
    handleSwiperDetail() {
      const swiperDetail = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          titleId: this.baner[this.currentIndex].id
        }
      });
      window.open(swiperDetail.href, "_blank");
    },
    // 即时热点详情
    hotNewsDetail(newsData) {
      const newsDetail = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          titleId: newsData.id
        }
      });
      window.open(newsDetail.href, "_blank");
    },

    // 图片
    async fetchBanner() {
      await getBanner({
        columnId: this.$route.query.columnNameId,
        type: 2
      }).then(res => {
        this.bannerImg = res.data;
      });
    },

    mouseOver() {
      clearInterval(this.timer);
    },
    mouseLeave() {
      this.runInv();
    },
    runInv() {
      //   this.timer = setInterval(() => {
      this.gotoPage(this.nextIndex);
      //   }, 3000);
    },
    // 分页
    gotoPage(index) {
      this.currentIndex = index;
    },
    handleSizeChange(val) {
      this.size = val;
      this.fetchThumbnailList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.fetchThumbnailList();
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom {
  height: 100%;
}
.left {
  width: 60%;
  height: 100%;
}
.right {
  //   max-width: 35%;
  width: 30%;
  height: 100%;
  margin-left: 5%;
}
.ul {
  height: 30px;
  padding-right: 20px;
  justify-content: space-between;
}
.ul .li {
  list-style: none;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  /* margin-top: 10px; */
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border-radius: 50%;
  border: 1px solid #fff;
  float: left;
}
.banner {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.banner img {
  width: 100%;
  height: 478px;
  display: block;
}
.banner .page {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 780px;
  align-items: center;
}
.banner .page .ul {
  float: right;
  padding-top: 10px;
}
.current {
  background-color: #fff;
  border: none;
  width: 12px;
  height: 12px;
}
.font {
  text-align: left;
  color: #fff;
  float: left;
  line-height: 40px;
  padding-left: 10px;
}
.list li div:nth-child(2) {
  width: 80%;
}
.list img {
  width: 130px;
  height: 90px;
  margin-right: 30px;
}
.list li {
  position: relative;
  border-bottom: 1px solid #bfbfbf;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
}
.list p {
  width: 100%;
}
.list .infor {
  margin: 10px 0;
  color: #000;
}
.list .source {
  position: absolute;
  bottom: 10px;
  color: #7f7f7f;
}
.right .top h1 {
  margin: 10px 0 30px;
  color: #51d5c3;
  font-weight: bold;
}
.right .top h1 span {
  color: #c00000;
  margin-left: 10px;
}
.right .top li {
  margin: 12px 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right .top li:hover {
  color: #51d5c3;
}
.right .top li span {
  background-color: #51d5c3;
  display: inline-block;
  padding: 0 5px;
  position: relative;
  top: -3px;
  left: -3px;
  color: #fff;
}
.right .top li i {
  border: 1px solid #51d5c3;
  margin-right: 10px;
}
.ad img {
  margin-top: 20px;
  width: 100%;
  height: 240px;
  cursor: pointer;
}
.Pagination {
  margin: 40px auto;
  margin-top: 50px;
}
.Pagination ul {
  justify-content: center;
}
.Pagination li:first-child > a {
  margin-left: 0px;
}
.Pagination a {
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover {
  background-color: #eee;
}
.Pagination a.banclick {
  cursor: not-allowed;
}
.Pagination .active a {
  color: #fff;
  cursor: default;
  background-color: #51d5c3;
  border-color: #51d5c3;
}
.Pagination i {
  font-style: normal;
  color: #51d5c3;
  margin: 0px 4px;
  font-style: 12px;
}
.head {
  border-top: 3px solid #51d5c3;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: left;
}
.head h1 {
  margin: 0 10px;
  font-weight: bold;
  color: #51d5c3;
}
.color2c {
  color: #2c2c2c !important;
}
.head span {
  color: #797979;
}
.head span + span::before {
  content: ">";
  margin: 0 5px;
  color: #797979;
}
.list .infor:hover {
  color: #51d5c3;
}
.container p span:nth-child(1),
.container p span:nth-child(2) {
  cursor: pointer;
}
.container p span:hover {
  color: #51d5c3;
}
.container p span:last-child:hover {
  color: #797979;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.no_data {
  height: 180px;
  line-height: 180px;
  color: #999;
  text-align: center;
}
</style>
<style scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #51d5c3;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #51d5c3;
}
/deep/.el-carousel__indicators--outside {
  display: none;
}
/deep/.el-carousel__arrow--left {
  display: none !important;
}
/deep/.el-carousel__arrow--right {
  display: none !important;
}
</style>
